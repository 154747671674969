@import "../node_modules/quill/dist/quill.bubble.css";
@import "../node_modules/quill/dist/quill.snow.css";

.ql-container {
    font-size: inherit !important;
    font-family: inherit !important;
}

.ql-bubble .ql-editor {
    @apply duration-200 focus:ring-2 focus:ring-offset-0 focus:ring-sky-400 transition-colors -m-1 border-none leading-5 focus:outline-none p-1 rounded-lg;
}

.ql-editor, .ra-quill, .ra-quill-container, .ra-markdown {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol,
    table,
    p {
        &:not(:first-child) {
            margin-top: 0.75rem;
        }
    }

    strong,
    b {
        font-weight: 700 !important;
    }

    code {
        background: rgba(0, 0, 0, .05);
        border-radius: 2px;
        padding: 2px;
    }

    ul, ol {
        display: block;
        list-style-type: disc;
        padding-left: 2rem;

        ul, ol {
            list-style-type: circle;
        }
    }

    ol {
        list-style-type: decimal;
    }

    blockquote {
        opacity: 0.6;
        font-style: italic;
    }

    table {
        border-spacing: initial;
        border-collapse: collapse;

        th, td {
            border: 1px solid #ddd;
            padding: 4px;
        }
    }

    a {
        @apply text-blue-600 underline cursor-pointer;
    }
}


.ra-quill-container {
    @apply has-[:focus]:outline-none has-[:focus]:ring-2 has-[:focus]:ring-cyan-400 has-[:focus]:ring-offset-0 has-[:focus]:rounded-md;

    .ql-snow {
        &.ql-toolbar {
            @apply rounded-t-md border-neutral-400 !important;
        }

        &.ql-container {
            @apply rounded-b-md border-neutral-400 text-neutral-800 !important;
        }
    }
}
