@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght,GRAD,XOPQ,XTRA,YOPQ,YTDE,YTFI,YTLC,YTUC@8..144,100..1000,-45,88,400,116,-250,600,500,725&display=swap');
@import "./quill.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

[x-cloak] {
    visibility: hidden;
}

#blazor-error-ui {
    display: none !important;
}

html, body {
    @apply bg-gray-200 font-sans;
}
